import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PageResponse } from '@mp/shared/data-access';
import { Recht } from '@mp/shared/data-access';

@Injectable({ providedIn: 'root' })
export class RechteService {
  constructor(private readonly httpClient: HttpClient) {}

  getAll(httpParams?: HttpParams): Observable<PageResponse<Recht>> {
    return this.httpClient.get<PageResponse<Recht>>('/api/core/authorization/rechte', { params: httpParams });
  }
}
