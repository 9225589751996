import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ROLLEN_FEATURE_KEY, RollenState } from './rollen.reducer';

const getRollenState = createFeatureSelector<RollenState>(ROLLEN_FEATURE_KEY);

const IS_LOADING = createSelector(getRollenState, (state) => state.isLoading);

const LIST = createSelector(getRollenState, (state) => state.entities);

const SELECTED = createSelector(getRollenState, (state) => state.selected);

export const rollenSelectors = {
  IS_LOADING,
  LIST,
  SELECTED,
};
