import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';

import { ApiBaseService } from '@mp/shared/data-access';

import { CreateRolle, Rolle, UpdateRolle } from './rolle';
import { CreateRolleBenutzer, RolleBenutzer } from './rolle-benutzer';
import { CreateRolleRecht, RolleRecht } from './rolle-recht';

@Injectable({ providedIn: 'root' })
export class RollenService extends ApiBaseService<Rolle, CreateRolle, UpdateRolle> {
  constructor(http: HttpClient) {
    super(http, '/api/rollen');
  }

  addBenutzerToRolle(benutzerToAdd: Array<CreateRolleBenutzer>): Observable<Array<RolleBenutzer>> {
    return forkJoin(benutzerToAdd.map((benutzer) => this.http.post<RolleBenutzer>('/api/rollenbenutzer', benutzer)));
  }

  removeBenutzerFromRolle(benutzerToRemove: Array<RolleBenutzer>): Observable<Array<RolleBenutzer>> {
    return forkJoin(
      benutzerToRemove.map((benutzer) => this.http.delete<RolleBenutzer>(`/api/rollenbenutzer/${benutzer.id}`)),
    );
  }

  addRechteToRolle(rechteToAdd: Array<CreateRolleRecht>): Observable<Array<RolleRecht>> {
    return forkJoin(rechteToAdd.map((rechte) => this.http.post<RolleRecht>('/api/rollenrechte', rechte)));
  }

  removeRechteFromRolle(rechteToRemove: Array<RolleRecht>): Observable<Array<RolleRecht>> {
    return forkJoin(rechteToRemove.map((recht) => this.http.delete<RolleRecht>(`/api/rollenrechte/${recht.id}`)));
  }
}
